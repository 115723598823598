body {
  position: relative;
	height: 100%;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	margin: 0;
	font-family: monospace;
	background-color: #596275;
	color: #424242;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input:focus {
  outline: none;
  box-shadow: 0px 0px 5px white;
}
input {
  border: none;
  padding: 10px;
  font-family: monospace;
}
button {
  font-family: monospace;
  color: black;
  border: 1px solid black;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  margin: 2px;
  outline:none;
  display: inline-block;
}
#root {
  display: flex;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#App {
  max-width: 1600px;
  margin: auto;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}
tbody >:nth-child(even) {
	background-color: white;
}
table {
	border-collapse: collapse;
	width: 100%;
}
table tr {
	background-color: #ddd;
}
table th {
	padding: 5px;
}
table td {
	min-height: 15px;
	padding: 5px;
}
td a {
  display: block;
  width: 100%;
  height: 100%;
}
.error {
  padding: 5px;
  text-align: center;
  color: white;
  background-color: #e55039;
}
.side {
  height: 50px;
  width: 50px;
}
.center {
  flex-grow: 1;
}
.side + .side {
  margin-left: 2%;
}
.fade-out {
  animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -webkit-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -moz-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -o-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -ms-animation: fadeOut 2s linear 0.5s 1 normal forwards;
  -webkit-animation: fadeOut 2s linear 0.5s 1 normal forwards;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
.bg-img {
  opacity: 0.5;
  background-image: url(/public/bg.jpg);
  background-size: cover;
}
tbody.striped >:nth-child(odd) {
	background-color: #F8F8F8;
}
tbody.striped >:nth-child(even) {
	background-color: white;
}
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
